angular.module('sq.jobs.submit.service', ['sq.jobs.model',
  'sq.jobs.tracker.service', 'sq.user.auth'])
  .factory('jobSubmit', JobSubmitFactory);

//This should be the single source of mutation for not-submitted jobs.
function JobSubmitFactory(Jobs, jobTracker, Auth, $log, $rootScope, $q) {
  var jS = { state: {} };

  let _parentJob = {};

  let _emailSet = false;
  let _optionsSet = false;

  // TODO: why doesn't this work if(!this instanceof JobTracker) { return new JobTracker(); }
  Object.defineProperties(jS, {
    parentJob: {
      get: () => _parentJob,
    },
  });

  jS.instanceParentJob = instanceParentJob;
  jS.spawn = spawn;
  jS.clearProgress = clearProgress;
  jS.hasParentJob = hasParentJob;
  jS.parentIsSubmitted = parentIsSubmitted;
  jS.submitAsync = submitAsync;
  jS.listen = listen;

  jS.state = { emailSet: false, optionsSet: false, experimentSet: false };

  jS.hasEmail = () => !!_parentJob.email;

  // mutate state
  jS.setEmail = (email) => {
    const tEmail = email || '';

    if (typeof tEmail !== 'string') {
      $log.error('email must be string');
      return;
    }

    _parentJob.email = tEmail;
  };

  jS.hasExperiment = () => !!_parentJob.experimentName;

  jS.getExperiment = () => _parentJob.experimentName;

  jS.setExperiment = (experimentName) => {
    const tExperiment = experimentName || '';

    if (typeof tExperiment !== 'string') {
      $log.error('Experiment must be string');
      return;
    }

    _parentJob.experimentName = tExperiment;
    jS.state.experimentSet = true;
  };

  jS.clearExperiment = () => {
    delete _parentJob.experimentName;
    jS.state.experimentSet = false;
  };

  return jS;

  function hasParentJob() {
    return Object.keys(_parentJob).length !== 0;
  }

  function parentIsSubmitted() {
    return !!_parentJob.submitted;
  }

  // public funciton definitions
  // Need to make unique publicID , because we use this to find people's jobs
  // Not a good idea... needs to change
  function spawn() {
    if (!hasParentJob()) {
      return instanceParentJob();
    }

    let clone = Object.assign({}, _parentJob);

    return new Jobs(clone);
  }

  function clearProgress() {
    jS.state.emailSet = false;
    jS.state.optionsSet = false;
    jS.state.experimentSet = false;
    delete _parentJob.experimentName;
  }

  //wrapped in this fashion to allow us to either make server calls
  //or more likely to allow the calling function to catch errors
  function submitAsync(job) {
    let err;
    if (!job) {
      err = new Error('no job found in submit');
      $log.error(err);
      return $q.reject(err);
    }

    if (!job.submission.state) {
      err = new Error('submitAsync expects job state');
      log.error(err);
      return $q.reject(err);
    }

    let returnJob;

    // TODO: handle error
    // TODO: probably run this through jobEvents, so that people may be notified of submission
    // it seems that isn't happening, not 100% sure why
    [err, returnJob] = jobTracker.trackJobUpdate(job);

    return $q.resolve(returnJob);
  }

  function instanceParentJob() {
    // angular.extend just references the passed src objects, so may
    // modify those src objects when destObj setters called, in the case
    // that one of the src object props refers to an object    
    _parentJob = Jobs.newSubmission();
    clearProgress();

    return _parentJob;
  }

  function listen() {
    $rootScope.$on(`${Auth.eventPrefix}loggedOut`, () => {
      instanceParentJob();
    });

    $rootScope.$on(`${Auth.eventPrefix}loggedIn`, () => {
      instanceParentJob();
    });
  }
}
